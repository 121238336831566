import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';

import "./blog.module.scss"
import headerStyles from "./blog.module.scss"

import Seo from "../components/seo"
import Header from "../components/header"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"
import Uryslygi from "../components/uryslygi"
import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"


import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"
import Blogs from "../components/blog"


import Map from "../components/map"


import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"

export const query = graphql `
	query($slug: String!) {
		blog: blog(slug: { eq: $slug }) {
			slug
			title
			description
			card
			img
			alt
			imgMini
			date
			datePublished
        	seoTitle
			seoDescription
			author
			ava
			time
			youtube
		}

		blogComponent: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}

	}
`
// data={data.blogComponent}
const Blog = ({data}) => {
	const merch = data.blog
	const blogs = data.blogComponent
	
	return (
		<Layout>
			<Seo title={merch.seoTitle} description={merch.seoDescription} />

			<Header title={merch.title} card={merch.card} />

			<ArticleJsonLd
				url={`https://юридические-услуги-казань.рф/blog/${merch.slug}`}
				headline={merch.seoTitle}
				images={[
					`https://юридические-услуги-казань.рф${merch.img}`,
				]}
				datePublished={merch.datePublished}
				dateModified={merch.datePublished}
				authorName={merch.author}
				publisherName={merch.author}
				publisherLogo='https://юридические-услуги-казань.рф/img/logo.png'
				description={merch.seoDescription}
				overrides={{
					'@type': 'BlogPosting',
				}}
			/>

			<div className={ `uk-section.uk-section-xsmall uk-margin-medium-top` }>
            	<div className={ `uk-container` }>

					<ul className={ `uk-breadcrumb` } >
						<li><a href="/">Главаная</a></li>
						<li><a href="/blog">Блог</a></li>
					</ul>

				</div>
			</div>

			<div className={ `uk-section uk-section-small ` }>
				<div className={ `uk-container` }>
					<div className={ `uk-grid-match uk-child-width-1-1@l` } uk-grid="true">

						<h2 className={ `${headerStyles.BlogHeader}` }>{merch.title}</h2>

						<hr className={ `uk-margin-medium-top` }></hr>

						<div className={ `uk-flex uk-flex-row uk-flex-left uk-margin-small-top uk-flex-middle ${headerStyles.BlogCardTitle}` } >
							
							<span className={ `${headerStyles.BlogCardAva}` } >
								<img className={ `${headerStyles.BlogCardDateImg}` } src={`${merch.ava}`} alt={merch.author}></img>
								{merch.author}
							</span>

							<span className={ `${headerStyles.BlogCardDate}` } >
								<span className={ `uk-margin-small-right` } uk-icon="calendar"></span>
								{merch.date}
							</span>
	
							<span className={ `${headerStyles.BlogCardTime}` } >
								<span className={ `uk-margin-small-right` } uk-icon="clock"></span> 
								{merch.time}
							</span>
												
						</div>

						<div className={ `uk-margin-remove` }>
							<div className={ `uk-flex uk-flex-column uk-flex-left` } >
								
								<div className={ `${headerStyles.BlogText}` } dangerouslySetInnerHTML={{ __html: merch.description }}></div>

							</div>
						</div>

					</div>

					<div className={ `uk-section.uk-section-xsmall ` }>
						<div className={ `uk-container` }>
							<div className={ `uk-child-width-1-1@s uk-child-width-1-1@m uk-grid-match uk-child-width-1-2@l uk-child-width-1-2@xl` } uk-grid="true">
						
								<div><iframe src={merch.youtube} height="300px"></iframe></div>
								
								<div></div>

							</div>
						</div>
					</div>

				</div>
			</div>

			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />

			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />


			<Social />
			<Certificate />
			<Users />

			<Blogs data={blogs} />

			<Map />
		
			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
		</Layout>
	)
}


export default Blog
